
.testimonalmain{
    padding: 2.5rem;

}
.testimonial-side{
    padding: 1rem;
    transition-timing-function: ease-in-out;
    transition-duration: 1.5s;
    scale: 0.9;
}
.testimonial-side:hover{
    scale: 1;
}
.testimonial-inner{
    padding: 1rem;
    border:2px solid var(--green);
    border-radius:1rem;
}
.testimonial-side::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height:5%;
    width: 5%;
    background: var(--green);
    z-index: -1;
    filter: blur(5rem);
    opacity: 0.2;
    transition-timing-function: ease-in-out;
    transition-duration: 1.5s;
}
.testimonial-side:hover::before{
    height: 100%;width:100%;
}
.testimonal-profile
{
    padding: 3rem ;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}
.profile-text span{
    font-size: 2.4rem;
    font-weight: 700;
    text-align: center;
}