.hero {
  position: relative;
  background: url('../../assets/images/circles.png') no-repeat center;
  background-size: 144rem;
  background-position: 0 -10rem;
  min-height: 100vh;
  z-index: 0
}

.herosecond {
  padding: 4rem 0;
}

.hero:before {
  content: '';
  position: absolute;
  top: -8rem;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/images/bg-gradient-img.png') no-repeat;
  background-size: 40rem;
  z-index: -1;
}

.hero .graphics img {
  width: 100%;
  animation: anim 30s linear infinite;
}

@keyframes anim {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }
}

.hero h1 {
  font-size: 8rem;
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 4rem;
}

.hero p {
  font-size: 2.1rem;
  font-weight: 200;
  letter-spacing: 0.1rem;
}

.our-specs {
  margin-top: 2rem;
}

.section-info {
  margin-bottom: 7rem;
}

.section-info .title {
  font-size: 5rem;
  line-height: 1;
  margin-bottom: 1rem;
}

.section-info .sub-title {
  font-size: 3.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.section-info p {
  max-width: 57rem;
  width: 100%;
  margin: 0 auto;
  font-size: 2rem;
  font-weight: 200;
}

.about .glass {
  background: url('/src/assets/images/glass.png') no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 2rem;
  padding: 3.5rem;
  padding-left: 10rem;
  overflow: hidden;
}

.about p {
  max-width: 48rem;
  width: 100%;
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 200;
}

.about center {
  margin-bottom: 5rem;
}

.about .wrapper {
  position: relative;
}

.about .wrapper::before,
.about .wrapper::after {
  content: '';
  position: absolute;
  height: 3rem;
  width: 3rem;
}

.about .wrapper::before {
  top: 0;
  right: 0;
  border-top: 0.1rem solid var(--white);
  border-right: 0.1rem solid var(--white);
}

.about .wrapper::after {
  bottom: 0;
  right: 0;
  border-bottom: 0.1rem solid var(--white);
  border-right: 0.1rem solid var(--white);
}

.about .tiles h2 {
  mix-blend-mode: multiply;
}

.about .tiles .column:first-child {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
}

.services {
  padding-top: 10rem;
  background: url('../../assets/images/services.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: 75%;
}

.services .glass {
  border-radius: 2rem;
  padding: 8rem 3rem;
  background: url('../../assets/images/services-bg.png') no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.services .glass .wrapper {
  background: var(--dark-purple);
  padding: 4.5rem;
  border-radius: 2rem;
}

.approach {
  padding: 7rem 0;
}

.approach .grid3x3 {
  max-width: 90%;
  margin: 0 auto;
}

.approach article.card {
  padding: 3rem;
  padding-bottom: 7rem;
  border: 0;
  background: var(--dark-purple);
  z-index: 1;
  overflow: hidden;
}

.approach article.card:hover::before {
  width: 100%;
  height: 60%;
}

.approach article.card::before {
  content: '';
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, -10%);
  height: 50%;
  width: 50%;
  border-radius: 50%;
  background: var(--green);
  z-index: -1;
  filter: blur(5rem);
  transition-timing-function: ease-in-out;
  transition-duration: 700ms;
}

.our-values .wrapper {
  max-width: 50rem;
  width: 100%;
  margin-bottom: 7rem;
}

.our-values .wrapper h3 {
  color: var(--white);
  font-size: 3.6rem;
  font-weight: 400;
}

.our-values .wrapper p {
  font-size: 2rem;
  color: var(--light-gray);
}

.our-values .values {
  justify-content: space-between;
}

.our-values .inner p {
  margin-bottom: 3rem;
}

.our-values .values h3 {
  font-size: 3.2rem;
  font-weight: 400;
}

.our-values .values hr {
  opacity: 0.5;
  margin: 4rem 0;
}

.our-values .values p {
  font-size: 2rem;
  max-width: 90%;
}

.our-values .button {
  all: unset;
  border: 0.1rem solid var(--white);
  border-radius: 2rem;
  padding: 0.2rem 3rem;
}

.our-values .column.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsletter {
  padding: 5rem 0;
}

.newsletter .glass {
  position: relative;
  min-height: 35rem;
  border-radius: 1.4rem;
  background: var(--dark-purple);
  padding: 5rem;
}

.newsletter .glass p {
  font-size: 1.8rem;
  color: var(--light-gray);
}

.newsletter .glass .row {
  position: absolute;
  bottom: 3rem;
}

.newsletter .glass .row a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3rem;
  text-transform: capitalize;
  border: 0.1rem solid var(--white);
  padding: 0.8rem 3rem;
}

.inner-icon {
  display: flex;
  gap: 5px;
}

.inner-icon:hover {
  background-color: var(--white);
  color: black;
}

.inner-icon:hover svg {
  color: black !important;
}

.inner-icon svg {
  font-size: 21px;
}


.newsletter .glass .row a img {
  height: 1.5rem;
  margin-left: 0.5rem;
}

.row.subscribe {
  height: 6rem;
  max-width: 40rem;
  width: 100%;
  border-radius: 3rem;
  padding: 1rem;
  background: var(--subscribe-bg);
  display: flex;
  justify-content: space-between;
}

.subscribe input {
  width: 100%;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0 1rem;
  font-size: 1.5rem;
  color: var(--white);
}

.subscribe input::placeholder {
  color: var(--placeholder-clr);
}

.subscribe button.subscribe {
  font-size: 1.5rem;
  border-radius: 2rem;
  outline: 0;
  border: 0;
  min-width: 13rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: 300ms;
}

.subscribe button.subscribe:hover {
  background: var(--white);
}

/* Media Queries */
@media only screen and (max-width: 1366px) {
  .hero h1 {
    font-size: 6.2rem;
  }

  .hero p {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 1200px) {
  .hero h1 {
    font-size: 6rem;
  }

  .section-info .title {
    font-size: 4.2rem;
  }

  h3 {
    font-size: 3.2rem;
  }

  .section-info .sub-title {
    font-size: 2.8rem;
  }

  .approach .grid3x3 {
    max-width: unset;
  }

  .newsletter .glass {
    padding: 4rem;
  }

  .subscribe button.subscribe {
    min-width: 10rem;
  }

  .newsletter .glass .row {
    max-width: unset;
    width: calc(100% - 8rem);
  }

  .newsletter .glass .row a {
    padding: 0.8rem 2rem;
  }

  .footer-links {
    padding-left: 0;
  }
}

@media only screen and (max-width: 1024px) {
  h3 {
    font-size: 2.6rem;
  }

  .hero h1 {
    font-size: 4.2rem;
    margin-bottom: 2rem;
  }

  .hero p {
    font-size: 1.8rem;
  }

  .section-info {
    margin-bottom: 3rem;
  }

  .section-info .title {
    font-size: 3.6rem;
  }

  .section-info .sub-title {
    font-size: 2.8rem;
  }

  .about .glass {
    padding: 1rem 2rem;
  }
}

@media only screen and (max-width: 991px) {
  .hero h1 {
    font-size: 3.6rem;
  }

  h3 {
    font-size: 2.2rem !important;
  }

  .our-specs {
    margin-top: 3rem;
  }

  .our-specs .container {
    padding: 0;
  }

  .about .tiles .column {
    width: 100%;
  }

  .about .tiles .column .inner {
    width: 100%;
  }

  .about .tiles .column:first-child {
    width: 100%;
    margin: 0.5rem 0;
  }

  .about .tiles .column {
    margin: 0.5rem 0;
  }

  .section-info .title {
    font-size: 3.2rem;
  }

  .section-info .sub-title {
    font-size: 2.4rem;
  }
}

@media only screen and (max-width: 767px) {
  header .nav {
    padding: 1rem 0;
  }

  header .nav .logo {
    max-width: 70%;
  }

  .hero h1 {
    font-size: 3.3rem;
  }

  .section-info .title,
  .our-values .wrapper h3,
  .our-values .values h3 {
    font-size: 2.8rem;
  }

  .section-info .sub-title {
    font-size: 2rem;
  }

  .our-specs .inner {
    padding: 0 1rem;
  }

  .our-values .values hr {
    margin: 2rem 0;
  }

  .about p,
  .section-info p,
  .our-values .values p,
  .newsletter .glass p {
    font-size: 1.6rem;
  }

  .our-values .inner p {
    max-width: unset;
    margin-bottom: 1rem;
  }

  .our-values .column.logo {
    justify-content: flex-start;
  }

  .our-values .column.logo img {
    max-width: 70%;
  }

  .services .glass,
  .services .glass .wrapper,
  .approach article.card,
  .newsletter .glass {
    padding: 2rem;
  }

  .approach article.card {
    padding-bottom: 6rem;
  }

  .row.subscribe {
    height: 5rem;
  }

  .newsletter .glass {
    min-height: unset;
  }

  .newsletter .glass .row {
    position: static;
    width: 100%;
    margin-top: 2.5rem;
  }
}

@media screen and (max-width:767px) {

  /* hero section */
  .herosecond {
    position: relative;
  }

  .herosecond .hero-text {
    display: flex;
    position: absolute;
    /* top: 30px; */
    padding: 0px 5px;
  }
}

@media screen and (max-width:440px) {

  /* hero section */
  .herosecond .hero-text {
    padding: 10px 0px;
  }

  .herosecond .hero-text p {
    text-align: justify;
    font-size: 3.5vw !important;
  }

  .hero-text .inner h1 {
    /* font-size: 2.8rem; */
    font-size: 8vw;
  }
}