/* loader section */
.mainloader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    background-color: black;


}

.mainloadercontain {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 50%;
    transform: rotate3d();
}

.mainloadercontain img {
    height: 50%;
    width: 50%;
    transition: all 0.5 ease;
    animation: rotate 2s ease-in-out 0s infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg) scale(0.1);
    }

    50% {
        transform: rotate(360deg) scale(1);
    }

    100% {
        transform: rotate(720deg) scale(0.1);
    }
}


.h2 {
    font-size: 28px;
}

.h2 span:nth-child(1) {
    animation: text 2s infinite 0.2s ease-in;
}

.h2 span:nth-child(2) {
    animation: text 2s infinite 0.4s ease-in;
}

.h2 span:nth-child(3) {
    animation: text 2s infinite 0.6s ease-in;
}

.h2 span:nth-child(4) {
    animation: text 2s infinite 0.8s ease-in;
}

.h2 span:nth-child(5) {
    animation: text 2s infinite 1s ease-in;
}

.h2 span:nth-child(6) {
    animation: text 2s infinite 1.2s ease-in;
}

.h2 span:nth-child(7) {
    animation: text 2s infinite 1.4s ease-in;
}

.h2 span:nth-child(8) {
    animation: text 2s infinite 1.6s ease-in;
}

.h2 span:nth-child(9) {
    animation: text 2s infinite 1.8s ease-in;
}

.h2 span:nth-child(10) {
    animation: text 2s infinite 2.0s ease-in;
}

.h2 span:nth-child(11) {
    animation: text 2s infinite 2.2s ease-in;
}

.h2 span:nth-child(12) {
    animation: text 2s infinite 2.4s ease-in;
}

.h2 span:nth-child(13) {
    animation: text 2s infinite 2.6s ease-in;
}

.h2 span:nth-child(14) {
    animation: text 2s infinite 2.8s ease-in;
}

.h2 span:nth-child(15) {
    animation: text 2s infinite 3s ease-in;
}

.h2 span:nth-child(16) {
    animation: text 2s infinite 3.2s ease-in;
}

.h2 span:nth-child(17) {
    animation: text 2s infinite 3.4s ease-in;
}

.h2 span:nth-child(18) {
    animation: text 2s infinite 3.5s ease-in;
}

.h2 span:nth-child(19) {
    animation: text 2s infinite 3.6s ease-in;
}

.h2 span:nth-child(20) {
    animation: text 2s infinite 3.8s ease-in;
}

.h2 span:nth-child(21) {
    animation: text 2s infinite 4.0s ease-in;
}

.h2 span:nth-child(22) {
    animation: text 2s infinite 4.2s ease-in;
}

.h2 span:nth-child(23) {
    animation: text 2s infinite 4.4s ease-in;
}

@keyframes text {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

}


ul {
    margin-bottom: 0px !important;
}

@media(max-width:400px) {
    .h2 {
        font-size: 16px;
    }
}